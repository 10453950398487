import { DocumentSigningStatus } from "@/constants/SignatureConstants";

export default class SignableDocumentsFilter {

    pageNumber = 1;
    pageSize = 20;
    signingStatus: DocumentSigningStatus | null = null;
    signerId: number | null = null;
    assigneeId: number | null = null;
    createDateFrom: Date | null = null;
    createDateTo: Date | null = null;
}
